import css from "./WhoWeAre.module.css";

import Footer from "../../components/Footer/Footer";
import Menu from "../../components/Menu/Menu";
import { globalContext } from "../../context/globalContext";

import { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";


function WhoWeAre() {

  const { toogleMenu } = useContext ( globalContext );

  const ourMission = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0,0);
    }, 700);
  }, []);

  function scrollDownOurMission() {

    ourMission.current.scrollIntoView(
      {
        behavior: 'smooth',
      }
    );
  }

  return (
    <>
      <div className={css.aboutMenu}>
          <Menu />
      </div>

      <div className={css.hero}>
        <div className={css.heroHeader}>
          <div className="title__container">
            <div className={css.titlePoint}></div>
            <p className="title">
              who we are
            </p>
          </div>
          <div className={css.menu}>
            <Link to='/contact'>
              <p className="talk">
                let's talk
              </p>
            </Link>
            <div className='header-burger'
                    onClick={toogleMenu} 
            >
              <span></span>
            </div>
          </div>
        </div>
        <div className={css.heroContainer}>
          <div className={css.heroMain}>
            <p className={css.heroMainText}>
              A team of prof–essionals
            </p>
          </div>
          <p className={css.heroMainDescription}>
            passionate about their work
          </p>
          <div className={css.heroButton}>
            <button
                  onClick={scrollDownOurMission}
            >
              <p className={css.heroButtonText}>
                mission
              </p>
            </button>
          </div>
        </div>
      </div>

      <div className={css.ourMission}
           ref={ourMission}
      >
        <div className={css.ourMissionContainer}>
          <div className={css.dot}></div>
          <div className={css.ourMissionText}>
            <p className={css.ourMissionTitleFirst}>
              Our
            </p>
            <p className={css.ourMissionTitleSecond}>
              Mission
            </p>
            <p className={css.ourMissionSubTitle}>
              Helping our clients make the right investment decisions
            </p>
            <p className={css.ourMissionDescription}>
              To do this, we have assembled a unique team of professionals specializing in strategy development, investment management and resolving complex corporate situations
            </p>
          </div>
          <div className={css.ourMissionImg}>
            <img src="/content/whoWeAre/ourMissionPlanet.png" alt="Planet Mission Image"/>
          </div>
          <div className={css.ourMissionArrow}>
          <img src="/content/general/arrow.svg" alt="Planet Arrow"/>
          </div>
          <div className={css.parnerCircleOrbit}></div>
          <div className={css.starOrbit}></div>
          <div className={css.star}></div>
          <div className={css.smallStar}></div>
        </div>
      </div>

      <div className={css.expertise}>
        <p className={css.expertiseTitle}>
          Expertise
        </p>
        <div className={css.expertiseDescription}>
          <p className={css.expertiseDescriptionText}>
            Experts in all aspects of business matters and corporate disputes
          </p>
          <div className={css.expertiseDescriptionImg}>
            <img src="/content/whoWeAre/planet.gif" alt="Planet Gif"/>
          </div>
        </div>
        <div className={css.expertiseAdvantages}>
          <div className={css.expertiseAdvantagesItem}>
            <img src="/content/whoWeAre/completed-investments-projects.png" alt="Completed investments projects"/>
          </div>
          <div className={css.expertiseAdvantagesItem}>
            <div className={css.expertiseAdvantagesItemGif}>
              <div className={css.expertiseAdvantagesItemGifText}>
                <p className={css.expertiseAdvantagesItemTitle}>
                  40+
                </p>
                <p className={css.expertiseAdvantagesItemText}>
                  Countries
                </p>
              </div>
              <div className={css.expertiseAdvantagesItemGifImg}>
                <img src="/content/whoWeAre/advantage.gif" alt="Expertise advantage Gif"/>
              </div>
            </div>
          </div>
          <div className={css.expertiseAdvantagesItem}>
            <img src="/content/whoWeAre/successful-lawsuits.png" alt="Successful lawsuits"/>
          </div>
          <div className={css.expertiseAdvantagesItem}>
            <img src="/content/whoWeAre/completed-M&A-transactions.png" alt="Completed M&A transactions"/>
          </div>
        </div>
      </div>

      <div className={css.perfomance}>
        <div className={css.perfomanceHero}>
          <div className={css.perfomanceMain}>
            <p className={css.perfomanceHeroTitle}>
              Perfor–mance
            </p>
            <p className={css.perfomanceHeroText}>
              Whether you're an enterprise corporation or a start-up, we give our clients the technical and business talent they need to succeessed
            </p>
          </div>
          <div className={css.perfomanceHeroImg}>
            <img src="/content/whoWeAre/perfomance.gif" alt="Perfomance background Gif"/>
          </div>
        </div>
        <div className={css.perfomanceAdvantages}>
          <div className={css.perfomanceAdvantagesItem}>
            <div className={css.perfomanceAdvantagesItemGif}>
              <div className={css.perfomanceAdvantagesItemGifText}>
                <p className={css.perfomanceAdvantagesItemTitle}>
                  100+
                </p>
                <p className={css.perfomanceAdvantagesItemText}>
                  Partners
                </p>
              </div>
              <div className={css.perfomanceAdvantagesItemGifImg}>
                <img src="/content/whoWeAre/advantage.gif" alt="Expertise advantage Gif"/>
              </div>
            </div>
          </div>
          <div className={css.perfomanceAdvantagesItem}>
            <img src="/content/whoWeAre/developed-investment-srtategys.png" alt="Developed investment srtategys"/>
          </div>
          <div className={css.perfomanceAdvantagesItem}>
            <img src="/content/whoWeAre/business-protection-options.png" alt="Business protection options"/>
          </div>
          <div className={css.perfomanceAdvantagesItem}>
            <img src="/content/whoWeAre/world-class-professionals.png" alt="World-class professionals"/>
          </div>
        </div>
      </div>

      <div className={css.partners}>
        <div className={css.partnersHero}>
          <p className={css.partnersHeroTitle}>
           The Trust of our Partners
          </p>
          <p className={css.partnersHeroText}>
            Our integrity is no accident; we earned it by being impeccable with the fulfilment of our obligations. Trust is the key to gaining a competitive advantage
          </p>
        </div>
        <div className={css.partnersImg}>
          <img src="/content/whoWeAre/partners.gif" alt="Partners background Gif"/>
        </div>
      </div>
      
      <Footer />
    </>
  )
}

export default WhoWeAre;
