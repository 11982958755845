import './App.css';

import Contact from './pages/Contact/Contact';
import WhoWeAre from './pages/WhoWeAre/WhoWeAre';
import Services from './pages/Services/Services';
import Hello from './pages/Hello/Hello';

import { Route, Routes, useLocation,} from 'react-router-dom';
import { globalContext } from "./context/globalContext";
import { useState } from 'react';
import { useTransition, animated} from 'react-spring';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


function App() {

  const [isActive, setIsActive] = useState(true);

  function toogleMenu() {
    setIsActive(!isActive);
  }

  const location = useLocation();
  
  const transitions = useTransition(location, {
    from: {
      opacity: 0,
      transition: 'opacity 0.3s ease-in-out',
    },
    enter: {
      opacity: 1,
      transition: 'opacity 0.5s ease-in-out',
    },
    leave: {
      opacity: 0,
    },
  });

  return (
    <>
      <globalContext.Provider value={ { isActive, toogleMenu, location } }>
          {transitions((props, item, key) => 
            <animated.div key={ key } style={ props }>
              <Routes location={ item }>
                <Route exact path="/" element={<Hello />} />
                <Route path="/about" element={<WhoWeAre />} />
                <Route path="/services" element={<Services />} />
                <Route path="/contact" element={<Contact />} />
              </Routes>
            </animated.div>
          )}
      </globalContext.Provider>
    </>
  );
}

export default App;
