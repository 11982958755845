import css from './Menu.module.css'

import { globalContext } from "../../context/globalContext";

import { useContext } from 'react';
import { Link } from 'react-router-dom';

function Menu() {

  const { isActive, toogleMenu } = useContext(globalContext);

  return (
    <>
      <div className={isActive ? css.menu : css.menuActive} >
        <div className={css.menuContainer}>
          <div className={css.menuContainerBlur}
               onClick={toogleMenu}
          >
          </div>
          <div className = {css.menuContainerRight}>
            <div className={css.menuBurgerContainer}>
              <div className={css.menuCrossContainer}>
                <div className={css.menuCross}
                     onClick={toogleMenu} 
                >
                  <span></span>
                </div>
              </div>
            </div>
            <p className={css.menuTitle}>
              menu
            </p>
            <ul className={css.menuList}>
              <li className={css.menuListItem}>
                <Link to="/" className={css.menuListItemText}
                             onClick={toogleMenu}
                >
                  hello
                </Link>
              </li>
              <li className={css.menuListItem}>
                <Link to="/about" className={css.menuListItemText}
                                  onClick={toogleMenu}
                >
                  about
                </Link>
              </li>
              <li className={css.menuListItem}>
                <Link to="/services" className={css.menuListItemText}
                                     onClick={toogleMenu}
                >
                  services
                </Link>
              </li>
              <li className={css.menuListItem}>
                <Link to="/contact" className={css.menuListItemText}
                                    onClick={toogleMenu}
                >
                  contact
                </Link>
              </li>
            </ul>
            {/* <div className={css.changeLang}>
              <p className={css.changeLangText}>
                cn
              </p> 
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Menu;
