import css from "./Services.module.css";

import Menu from "../../components/Menu/Menu";
import Footer from "../../components/Footer/Footer";
import { globalContext } from "../../context/globalContext";

import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";


function Services() {

  const { toogleMenu } = useContext( globalContext );

  // Investment Accordion Section

  const [setActiveInvestment, setActiveInvestmentState] = useState("");
  const [setHeightInvestment, setHeightInvestmentState] = useState("0px");
  const [setRotateInvestment, setRotateInvestmentState] = useState(`${css.accordionIcon}`);
  
  const contentInvestment = useRef(null);

  function toggleAccordionInvestment() {
    setActiveInvestmentState(setActiveInvestment === "" ? "active" : "");
    setHeightInvestmentState(
      setActiveInvestment === "active" ? "0px" : `${contentInvestment.current.scrollHeight}px`
    );
    setRotateInvestmentState(
      setActiveInvestment === "active" ? `${css.accordionIcon}` : `${css.accordionIcon} ${css.rotate}`
    );

    if(!setActiveInvestment) {

      if(setActiveFollowUp) {
        toggleAccordionFollowUp();
      }

      if(setActiveAssetManagment) {
        toggleAccordionAssetManagment();
      }

      if(setActiveAntiCrisisManagment) {
        toggleAccordionAntiCrisisManagment();
      }

    }
  }

  // Follow Up Accordion Section

  const [setActiveFollowUp, setActiveFollowUpState] = useState("");
  const [setHeightFollowUp, setHeightFollowUpState] = useState("0px");
  const [setRotateFollowUp, setRotateFollowUpState] = useState(`${css.accordionIcon}`);

  const contentFollowUp = useRef(null);

  function toggleAccordionFollowUp() {
    setActiveFollowUpState(setActiveFollowUp === "" ? "active" : "");
    setHeightFollowUpState(
      setActiveFollowUp === "active" ? "0px" : `${contentFollowUp.current.scrollHeight}px`
    );
    setRotateFollowUpState(
      setActiveFollowUp === "active" ? `${css.accordionIcon}` : `${css.accordionIcon} ${css.rotate}`
    );

    if(!setActiveFollowUp) {

      if(setActiveInvestment) {
        toggleAccordionInvestment();
      }

      if(setActiveAssetManagment) {
        toggleAccordionAssetManagment();
      }

      if(setActiveAntiCrisisManagment) {
        toggleAccordionAntiCrisisManagment();
      }

    }
  }

  // Asset Managment Accordion Section

  const [setActiveAssetManagment, setActiveAssetManagmentState] = useState("");
  const [setHeightAssetManagment, setHeightAssetManagmentState] = useState("0px");
  const [setRotateAssetManagment, setRotateAssetManagmentState] = useState(`${css.accordionIcon}`);

  const contentAssetManagment = useRef(null);

  function toggleAccordionAssetManagment() {
    setActiveAssetManagmentState(setActiveAssetManagment === "" ? "active" : "");
    setHeightAssetManagmentState(
      setActiveAssetManagment === "active" ? "0px" : `${contentAssetManagment.current.scrollHeight}px`
    );
    setRotateAssetManagmentState(
      setActiveAssetManagment === "active" ? `${css.accordionIcon}` : `${css.accordionIcon} ${css.rotate}`
    );

    if(!setActiveAssetManagment) {

      if(setActiveInvestment) {
        toggleAccordionInvestment();
      }

      if(setActiveFollowUp) {
        toggleAccordionFollowUp();
      }

      if(setActiveAntiCrisisManagment) {
        toggleAccordionAntiCrisisManagment();
      }

    }
  }

  // Anti-Crisis Management Accordion Section

  const [setActiveAntiCrisisManagment, setActiveAntiCrisisManagmentState] = useState("");
  const [setHeightAntiCrisisManagment, setHeightAntiCrisisManagmentState] = useState("0px");
  const [setRotateAntiCrisisManagment, setRotateAntiCrisisManagmentState] = useState(`${css.accordionIcon}`);

  const contentAntiCrisisManagment = useRef(null);

  function toggleAccordionAntiCrisisManagment() {
    setActiveAntiCrisisManagmentState(setActiveAntiCrisisManagment === "" ? "active" : "");
    setHeightAntiCrisisManagmentState(
      setActiveAntiCrisisManagment === "active" ? "0px" : `${contentAntiCrisisManagment.current.scrollHeight}px`
    );
    setRotateAntiCrisisManagmentState(
      setActiveAntiCrisisManagment === "active" ? `${css.accordionIcon}` : `${css.accordionIcon} ${css.rotate}`
    );

    if(!setActiveAntiCrisisManagment) {

      if(setActiveInvestment) {
        toggleAccordionInvestment();
      }

      if(setActiveFollowUp) {
        toggleAccordionFollowUp();
      }

      if(setActiveAssetManagment) {
        toggleAccordionAssetManagment();
      }

    }
  }

  // Letter Accordion Section

  const [setActiveLetterA, setActiveLetterAState] = useState("");
  const [setHeightLetterA, setHeightLetterAState] = useState("0px");
  const [setRotateLetterA, setRotateLetterAState] = useState(`${css.ourProjectsAccordionIcon}`);

  const contentLetterA = useRef(null);

  function toggleAccordionLetterA() {
    setActiveLetterAState(setActiveLetterA === "" ? `${css.active}` : "");
    setHeightLetterAState(
      setActiveLetterA === `${css.active}` ? "0px" : `${contentLetterA.current.scrollHeight}px`
    );
    setRotateLetterAState(
      setActiveLetterA === `${css.active}` ? `${css.ourProjectsAccordionIcon}` : `${css.ourProjectsAccordionIcon} ${css.ourProjectsRotate}`
    );

    if(!setActiveLetterA) {

      if(setActiveRidotto) {
        toggleAccordionRidotto();
      }

    }
  }

  // Ridotto Entertaiment Accordion Section

  const [setActiveRidotto, setActiveRidottoState] = useState("");
  const [setHeightRidotto, setHeightRidottoState] = useState("0px");
  const [setRotateRidotto, setRotateRidottoState] = useState(`${css.ourProjectsAccordionIcon}`);
  
  const contentRidotto = useRef(null);
  
  function toggleAccordionRidotto() {
    setActiveRidottoState(setActiveRidotto === "" ? `${css.active}` : "");
    setHeightRidottoState(
      setActiveRidotto === `${css.active}` ? "0px" : `${contentRidotto.current.scrollHeight}px`
    );
    setRotateRidottoState(
      setActiveRidotto === `${css.active}` ? `${css.ourProjectsAccordionIcon}` : `${css.ourProjectsAccordionIcon} ${css.ourProjectsRotate}`
    );

    if(!setActiveRidotto) {

      if(setActiveLetterA) {
        toggleAccordionLetterA();
      }

    }
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0,0);
    }, 700);
  }, [])

  return (
    <div className={css.services}>
      <div className={css.servicesMenu}>
        <Menu />
      </div>
      <div className={css.hero}>
        <div className={css.heroHeader}>
          <div className="title__container">
            <div className={css.titlePoint}></div>
            <p className="title">
              services
            </p>
          </div>
          <div className={css.menu}>
            <Link to='/contact'>
              <p className="talk">
                let's talk
              </p>
            </Link>
            <div className='header-burger'
                  onClick={toogleMenu} 
            >
              <span></span>
            </div>
          </div>
        </div>
        <div className={css.heroMainContainer}>
          <div className={css.heroMain}>
            <p className={css.heroTitle}>
              What we &nbsp;&nbsp;&nbsp;do?
            </p>
            <div className={css.heroArrow}>
              <img src="/content/general/arrow.svg" alt="Arrow"/>
            </div>
            <div className={css.heroImg}>
              <img src="/content/services/hero-background.gif" alt="Hero Background Gif"/>
            </div>
          </div>
        </div>
        <div className={css.heroDescription}>
          <p className={css.heroDescriptionText}>
            We keep things simple, we work fast, and we use a collaborative and transparent approach to get deals done
          </p>
        </div>
        <div className={css.heroPoliceLine}></div>
        <div className={css.heroCapitalLine}></div>
      </div>
      
      <div className={css.ourServices}>
        <div className={css.ourServicesMain}>
          <div className={css.ourServicesMainLeft}>
            <p className={css.ourServicesMainTitle}>
              Our services
            </p>
            <div className={css.ourServicesMainLeftImg}>
              <img src="/content/services/services-main.svg" alt="Our Services Main Img"/>
            </div>
          </div>
          <div className={css.ourServicesRight}>
            <ul className={css.accordionList}>
            <li className={css.accordionItem}>
              <div className={css.accordionSection}>
                <button className={`${css.accordion} ${setActiveInvestment}`}
                        onClick={toggleAccordionInvestment}
                >
                  <p className={css.accordionTitle}>
                    Investment
                  </p>
                  <div className={`${setRotateInvestment}`}>
                  </div>
                </button>
                <div
                    ref={contentInvestment}
                    style={{ maxHeight: `${setHeightInvestment}` }}
                    className={css.accordionContent}
                >
                  <div className={css.accordionText}>
                    <p>
                      <span className={css.accordionTextTitle}>
                        Extensive experience in the field of investing and developing investment companies Alphabet Capital from private discussions on resolving complex economic and corporate disputes. <br /> <br />
                        We carry out all types of investment projects: <br />
                      </span>
                      <span className={css.accordionTextContent}>
                        restructuring of distressed corporate debt <br />
                        crisis management <br />
                        management of an enterprise or investment portfolio (from the position of an active investor) <br /> <br />
                      </span>
                      <span className={css.accordionTextTitle}>
                        The main direction is the acquisition and rehabilitation of depressed assets and their further sale <br /> <br />
                        
                        Cooperation with us is: <br />
                      </span>
                      <span className={css.accordionTextContent}>
                        easily resolve complex corporate situations <br />
                        all the possibilities of exercising the rights of shareholders <br />
                        protection against hostile takeovers and other hostile actions <br />
                        all the necessary resources, including our experience and capital, to implement portfolio growth strategies 
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li className={css.accordionItem}>
              <div className={css.accordionSection}>
                <button className={`${css.accordion} ${setActiveFollowUp}`}
                        onClick={toggleAccordionFollowUp}
                >
                  <p className={css.accordionTitle}>
                    Follow Up
                  </p>
                  <div className={`${setRotateFollowUp}`}>
                  </div>
                </button>
                <div
                    ref={contentFollowUp}
                    style={{ maxHeight: `${setHeightFollowUp}` }}
                    className={css.accordionContent}
                >
                  <div className={css.accordionText}>
                    <p>
                      <span className={css.accordionTextTitle}>
                        Our lawyers and financiers <br /> <br />
                      </span>
                      <span className={css.accordionTextContent}>
                        have solid experience in corporate finance and corporate governance <br /> <br />

                        took part in more than 500 lawsuits (in various international jurisdictions) related to protecting the interests of investors and combating hostile takeovers. <br /> <br />

                        formed an extensive law enforcement practice, developed many options for counteracting unscrupulous debtors and creditors. <br /> <br />
                      </span>
                      <span className={css.accordionTextTitle}>
                        Ready to develop and implement: <br /> <br />
                      </span>
                      <span className={css.accordionTextContent}>
                        defense strategies and tactics <br />
                        business restructuring schemes <br />
                        mechanisms for control over the disposal of property <br />
                        organization of project financing <br />
                        support of M&A transactions <br />
                        legal and financial support of investment projects <br />
                        solid experience in corporate finance and corporate governance. <br />
                        comprehensive hostile takeover protection system <br />
                        legal analysis of the existing corporate structure"
                      </span>
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </li>
            <li className={css.accordionItem}>
              <div className={css.accordionSection}>
                <button className={`${css.accordion} ${setActiveAssetManagment}`}
                        onClick={toggleAccordionAssetManagment}
                >
                  <p className={css.accordionTitle}>
                    Asset Management
                  </p>
                  <div className={`${setRotateAssetManagment}`}>
                  </div>
                </button>
                <div
                    ref={contentAssetManagment}
                    style={{ maxHeight: `${setHeightAssetManagment}` }}
                    className={css.accordionContent}
                >
                  <div className={css.accordionText}>
                    <p>
                      <span className={css.accordionTextTitle}>
                        We have experience in resolving situations where there are disagreements between the parties on financial or business issues, in investigating the actions of employees or organizations. <br /> <br />

                        We organize events to control the top management of the enterprise from the position of an active shareholder. <br /> <br />

                        We provide a wide range of services in the field of investment consulting and corporate governance: <br /> <br />
                      </span>
                      <span className={css.accordionTextContent}>
                        manage financial risks, reducing the likelihood of an unfavorable outcome <br />
                        seek to minimize possible losses <br />
                        assist clients in making investment decisions and asset management <br />
                        we carry out special types of assessment of the economic efficiency of an asset or an investment project for our clients and partners"
                      </span>
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </li>
            <li className={css.accordionItem}>
              <div className={css.accordionSection}>
                <button className={`${css.accordion} ${setActiveAntiCrisisManagment}`}
                        onClick={toggleAccordionAntiCrisisManagment}
                >
                  <p className={css.accordionTitle}>
                    Anti-Crisis Management
                  </p>
                  <div className={`${setRotateAntiCrisisManagment}`}>
                  </div>
                </button>
                <div
                    ref={contentAntiCrisisManagment}
                    style={{ maxHeight: `${setHeightAntiCrisisManagment}` }}
                    className={css.accordionContent}
                >
                  <div className={css.accordionText}>
                    <p>
                      <span className={css.accordionTextTitle}>
                        We have extensive experience in pre-trial settlement of economic disputes, resolving business conflicts and making positive decisions at an early stage. <br /> <br />

                        We use various legal instruments and anti-crisis communications. <br /> <br />

                        We are able to predict, prevent and take control of negative consequences in various areas. <br /> <br />

                        We not only manage complex crisis situations, but also minimize risk and uncertainty, with an eye to gaining control over what is happening. <br /> <br />

                        We act as mediators in disputable situations and always strive to find the best solutions.
                      </span>
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </li>
            </ul>
          </div>
        </div>
        <div className={css.ourProjects}>
          <div className={css.ourProjectsImg}>
            <img src="/content/general/star.svg" alt="Star"/>
          </div>
          <p className={css.ourText}>
            Our
          </p>
          <p className={css.projectsText}>
            Projects
          </p>
          <div className={css.accordion}>
          <ul className={css.ourProjectsAccordionList}>
            <li className={css.accordionItem}>
              <div className={css.accordionSection}>
                <button className={`${css.accordion} ${setActiveLetterA}`}
                        onClick={toggleAccordionLetterA}
                >
                  <p className={`${css.ourProjectsAccordionTitle} ${setActiveLetterA}`}>
                    Letter A
                  </p>
                  <div>
                    <svg className={`${setRotateLetterA}`} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" />
                      <path d="M19.9998 8.14355L21.6766 18.3231L31.8561 19.9998L21.6766 21.6766L19.9998 31.8561L18.3231 21.6766L8.14355 19.9998L18.3231 18.3231L19.9998 8.14355Z" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M20 37.5689C29.703 37.5689 37.5689 29.703 37.5689 20C37.5689 10.297 29.703 2.43114 20 2.43114C10.297 2.43114 2.43114 10.297 2.43114 20C2.43114 29.703 10.297 37.5689 20 37.5689ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z" />
                    </svg>
                  </div>
                </button>
                <div
                    ref={contentLetterA}
                    style={{ maxHeight: `${setHeightLetterA}` }}
                    className={css.accordionContent}
                >
                  <div className={`${css.ourProjectsAccordionText} ${setActiveLetterA}`}>
                    <p>
                      London based, family investment firm  
                    </p>
                    <br />
                    <div className={css.ourProjectsAccordionLink}>
                      <a href="https://lettera.us" target='_blank' rel="noreferrer"> 
                        https://lettera.us 
                      </a> 
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className={css.accordionItem}>
              <div className={css.accordionSection}>
                <button className={`${css.accordion} ${setActiveRidotto}`}
                        onClick={toggleAccordionRidotto}
                >
                  <p className={`${css.ourProjectsAccordionTitle} ${setActiveRidotto}`}>
                    Ridotto Entertaiment
                  </p>
                  <div>
                    <svg className={`${setRotateRidotto}`} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" />
                      <path d="M19.9998 8.14355L21.6766 18.3231L31.8561 19.9998L21.6766 21.6766L19.9998 31.8561L18.3231 21.6766L8.14355 19.9998L18.3231 18.3231L19.9998 8.14355Z" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M20 37.5689C29.703 37.5689 37.5689 29.703 37.5689 20C37.5689 10.297 29.703 2.43114 20 2.43114C10.297 2.43114 2.43114 10.297 2.43114 20C2.43114 29.703 10.297 37.5689 20 37.5689ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z" />
                    </svg>
                  </div>
                </button>
                <div
                    ref={contentRidotto}
                    style={{ maxHeight: `${setHeightRidotto}` }}
                    className={css.accordionContent}
                >
                  <div className={`${css.ourProjectsAccordionText} ${setActiveRidotto}`}>
                    <p>
                      Investments in the entertaiment industry  
                    </p>
                    <br />
                    <div className={css.ourProjectsAccordionLink}>
                      <a href="https://ridotto.biz" target='_blank' rel="noreferrer"> 
                        https://ridotto.biz 
                      </a> 
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        </div>
        <div className={css.ourServicesGif}>
          <img src="/content/services/services-footer.gif" alt="Services Footer Gif"/>
        </div>
        <div className={css.ourServicesFooter}>
          <p className={css.ourServicesFooterText}>
            We are a team of world-class professionals. A team that can build and scale your vision in the most efficient way possible
          </p>
          <p className={css.ourServicesFooterTitle}>
            We work fast and keep it simple
          </p>
          <div className={css.ourSevicesFooterCircleImg}>
            <img src="/content/services/partner-circle.png" alt="Your Partner"/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Services;
