import css from "./Hello.module.css";

import Footer from "../../components/Footer/Footer";
import Menu from "../../components/Menu/Menu";
import { globalContext } from "../../context/globalContext";

import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


function Hello() {

  gsap.registerPlugin(ScrollTrigger);

  const { toogleMenu } = useContext( globalContext );

  // Investment Accordion Section

  const [setActiveInvestment, setActiveInvestmentState] = useState("");
  const [setHeightInvestment, setHeightInvestmentState] = useState("0px");
  const [setRotateInvestment, setRotateInvestmentState] = useState(`${css.accordionIcon}`);

  const contentInvestment = useRef(null);

  function toggleAccordionInvestment() {
    setActiveInvestmentState(setActiveInvestment === "" ? `${css.active}` : "");
    setHeightInvestmentState(
      setActiveInvestment === `${css.active}` ? "0px" : `${contentInvestment.current.scrollHeight}px`
    );
    setRotateInvestmentState(
      setActiveInvestment === `${css.active}` ? `${css.accordionIcon}` : `${css.accordionIcon} ${css.rotate}`
    );

    if(!setActiveInvestment) {

      if(setActiveFollowUp) {
        toggleAccordionFollowUp();
      }

      if(setActiveAssetManagment) {
        toggleAccordionAssetManagment();
      }

      if(setActiveAntiCrisisManagment) {
        toggleAccordionAntiCrisisManagment();
      }

    }
  }

  // Follow Up Accordion Section

  const [setActiveFollowUp, setActiveFollowUpState] = useState("");
  const [setHeightFollowUp, setHeightFollowUpState] = useState("0px");
  const [setRotateFollowUp, setRotateFollowUpState] = useState(`${css.accordionIcon}`);

  const contentFollowUp = useRef(null);

  function toggleAccordionFollowUp() {
    setActiveFollowUpState(setActiveFollowUp === "" ? `${css.active}` : "");
    setHeightFollowUpState(
      setActiveFollowUp === `${css.active}` ? "0px" : `${contentFollowUp.current.scrollHeight}px`
    );
    setRotateFollowUpState(
      setActiveFollowUp === `${css.active}` ? `${css.accordionIcon}` : `${css.accordionIcon} ${css.rotate}`
    );

    if(!setActiveFollowUp) {

      if(setActiveInvestment) {
        toggleAccordionInvestment();
      }

      if(setActiveAssetManagment) {
        toggleAccordionAssetManagment();
      }

      if(setActiveAntiCrisisManagment) {
        toggleAccordionAntiCrisisManagment();
      }

    }
  }

  // Asset Managment Accordion Section

  const [setActiveAssetManagment, setActiveAssetManagmentState] = useState("");
  const [setHeightAssetManagment, setHeightAssetManagmentState] = useState("0px");
  const [setRotateAssetManagment, setRotateAssetManagmentState] = useState(`${css.accordionIcon}`);

  const contentAssetManagment = useRef(null);

  function toggleAccordionAssetManagment() {
    setActiveAssetManagmentState(setActiveAssetManagment === "" ? `${css.active}` : "");
    setHeightAssetManagmentState(
      setActiveAssetManagment === `${css.active}` ? "0px" : `${contentAssetManagment.current.scrollHeight}px`
    );
    setRotateAssetManagmentState(
      setActiveAssetManagment === `${css.active}` ? `${css.accordionIcon}` : `${css.accordionIcon} ${css.rotate}`
    );

    if(!setActiveAssetManagment) {

      if(setActiveInvestment) {
        toggleAccordionInvestment();
      }

      if(setActiveFollowUp) {
        toggleAccordionFollowUp();
      }

      if(setActiveAntiCrisisManagment) {
        toggleAccordionAntiCrisisManagment();
      }

    }
  }

  // Anti-Crisis Management Accordion Section

  const [setActiveAntiCrisisManagment, setActiveAntiCrisisManagmentState] = useState("");
  const [setHeightAntiCrisisManagment, setHeightAntiCrisisManagmentState] = useState("0px");
  const [setRotateAntiCrisisManagment, setRotateAntiCrisisManagmentState] = useState(`${css.accordionIcon}`);

  const contentAntiCrisisManagment = useRef(null);

  function toggleAccordionAntiCrisisManagment() {
    setActiveAntiCrisisManagmentState(setActiveAntiCrisisManagment === "" ? `${css.active}` : "");
    setHeightAntiCrisisManagmentState(
      setActiveAntiCrisisManagment === `${css.active}` ? "0px" : `${contentAntiCrisisManagment.current.scrollHeight}px`
    );
    setRotateAntiCrisisManagmentState(
      setActiveAntiCrisisManagment === `${css.active}` ? `${css.accordionIcon}` : `${css.accordionIcon} ${css.rotate}`
    );

    if(!setActiveAntiCrisisManagment) {

      if(setActiveInvestment) {
        toggleAccordionInvestment();
      }

      if(setActiveFollowUp) {
        toggleAccordionFollowUp();
      }

      if(setActiveAssetManagment) {
        toggleAccordionAssetManagment();
      }

    }
  }
  
  const hero = useRef(null);
  const mainScrollDown = useRef(null);
  const titleContainer = useRef(null);
  const advantageTitle = useRef(null);
  const directionsTitle = useRef(null);
  const heroBubbles = useRef(null);
  const heroMessage = useRef(null);
  const heroMessageMainText = useRef(null);
  const heroMessageText = useRef(null);
  const heroMessageFooter = useRef(null);
  const advantageBubbles = useRef(null);
  const expertise = useRef(null);
  const advantageScrollDown = useRef(null);
  const perfomance = useRef(null);
  const trust = useRef(null);

  function scrollDownHero() {
    
    hero.current.scrollIntoView(
      { 
        behavior: 'smooth',
      }
    );
  }

  useEffect(() => {

    setTimeout(() => {
      window.scrollTo(0,0);
    }, 700);

  }, []);

  useLayoutEffect(() => {

    setTimeout(() => {

      const heroMessageTextTimeline = gsap.timeline();
      const heroBubblesTimeLine = gsap.timeline();
      const advantageBubblesTimeLine = gsap.timeline();
      const expertiseTimeLine = gsap.timeline();
      const perfomanceTimeLine = gsap.timeline();
      const trustTimeLine = gsap.timeline();
  
      heroBubblesTimeLine
        .fromTo(mainScrollDown.current, {
          opacity: 0,
        },
        {
          opacity: 1,
          zIndex: 3,
        })
        .fromTo(heroBubbles.current, {
          y: '-20%',
        }, 
        {
          y: '-25%',
        })
        .fromTo(mainScrollDown.current, {
          opacity: 0,
        },
        {
          opacity: 0,
        })
        
  
      heroMessageTextTimeline
        .fromTo(mainScrollDown.current, {
          zIndex: 3,
        },
        {
          zIndex: 4,
        })
        .fromTo(heroMessageText.current, {
          y: '500%',
          duration: '1',
        },
        {
          y: '0%',
          duration: '1',
          ease: "back.out(1.7)",
        })
        .fromTo(heroMessageFooter.current, {
          y: '500%',
          duration: '1',
        },
        {
          y: '0%',
          duration: '1',
          ease: "back.out(1.7)",
        }, "+= 2");
  
      advantageBubblesTimeLine
        .fromTo(advantageBubbles.current, {
          y: '0%',
        }, 
        {
          y: '-25%',
        });
  
      expertiseTimeLine
        .to(directionsTitle.current, {
          opacity: 0,
          duration: 0,
        })
        .to(titleContainer.current, {
          opacity: 1,
        })
        .to(directionsTitle.current, {
          display: 'none',
        })
        .fromTo(expertise.current, {
          opacity: '1',
        }, 
        {
          opacity: '0',
        }, "-=0.5")
        .fromTo(mainScrollDown.current, {
          opacity: 0,
        },
        {
          opacity: 1,
        }, "-=1")
  
      perfomanceTimeLine
        .fromTo(perfomance.current, {
          opacity: '1',
        }, 
        {
          opacity: '0',
        });
  
      trustTimeLine
        .to(advantageTitle.current, {
          display: 'none',
        })
        .fromTo(mainScrollDown.current, {
          opacity: 1,
        },
        {
          opacity: 0,
        }, "-=1")
        .to(directionsTitle.current, {
          display: 'block',
          opacity: 1,
        })
  
      ScrollTrigger.create({
        animation: heroBubblesTimeLine,
        trigger: hero.current,
        pin: true,
        pinSpacing: false,
        scrub: 1,
        start: 'top top', 
        end: '200%', 
        },
      );
  
      gsap.timeline({
        scrollTrigger: {
          trigger: heroBubbles.current,
          start: 'top top', 
          end: () => `+=${heroBubbles.current?.offsetHeight}`, 
          pin: true, 
          pinSpacing: false,
          scrub: 1,
        },
      });
  
      ScrollTrigger.create({
        animation: heroMessageTextTimeline,
        trigger: heroMessage.current,
        start: 'top top',
        end: () => `+=${heroMessage.current?.offsetHeight}`, 
        pin: true,
        pinSpacing: true,
        scrub: 1,
        },
      );
  
      ScrollTrigger.create({
        animation: advantageBubblesTimeLine,
        trigger: advantageBubbles.current,
        start: 'top top',
        end: '400%',  
        pin: true,
        pinSpacing: false,
        scrub: 1,
        },
      );
  
      ScrollTrigger.create({
        animation: expertiseTimeLine,
        trigger: expertise.current,
        start: '-30%',
        end: () => `+=${expertise.current?.offsetHeight}`,
        pin: false,
        pinSpacing: false,
        scrub: 1,
        },
      );
  
      ScrollTrigger.create({
        animation: perfomanceTimeLine,
        trigger: perfomance.current,
        start: 'top top',
        end: () => `+=${perfomance.current?.offsetHeight}`,
        pin: false,
        pinSpacing: false,
        scrub: 1,
        },
      );
  
      ScrollTrigger.create({
        animation: trustTimeLine,
        trigger: trust.current,
        start: 'top top',
        end: () => `+=${trust.current?.offsetHeight}`,
        pin: false,
        pinSpacing: false,
        scrub: 1,
        },
      );
    }, 1000);

  }, []);

  return (
    <div className={css.hello}>

      <div className={css.helloMenu}>
        <Menu />
      </div>

      <div className={css.heroHeader}>
        <div className={css.heroTitleContainer}
             ref={titleContainer}
        >
            <div className={css.titlePoint}></div>
            <p className="title"
               ref={advantageTitle}
            >
              advantage
            </p>
            <p className="title"
               ref={directionsTitle}
            >
              directions
            </p>
        </div>
        <div className={css.menu}>
          <Link to='/contact'>
            <p className="talk">
              let's talk
            </p>
          </Link>
          <div className='header-burger'
                onClick={toogleMenu} 
          >
            <span></span>
          </div>
        </div>
      </div>

      <div className={css.mainScrollDownContainer}
           ref={mainScrollDown} 
      >
            <div className={css.mainScrollDown}>
              <img src="/content/hello/scroll-down.svg" alt="Scroll Down"/>
            </div>
            <div className={css.mainScrollDownArrow}>
              <img src="/content/general/arrow.svg" alt="Arrow"/>
            </div>
          </div>
      
      <div className={css.start}>
        <div className={css.startLogo}>
          <img src="/content/hello/logo.svg" alt="Alphabet Capital Logo"/>
        </div>
        <div className={css.startAlphabet}>
          <img src="/content/hello/alphabet.svg" alt="Alphabet"/>
        </div>
        <div className={css.startCapital}>
          <img src="/content/hello/capital.svg" alt="Capital"/>
        </div>
        <div className={css.startScrollDownContainer}>
          <div className={css.startScrollDown}
               onClick={scrollDownHero}
          >
              <img src="/content/hello/scroll-down.svg" alt="Scroll Down"/>
          </div>
          <div className={css.startScrollDownArrow}>
            <img src="/content/general/arrow.svg" alt="Arrow"/>
          </div>
        </div>
      </div>

      <div className={css.hero}
            id="hero"
            ref={hero}
      >
        <div className={css.heroMain}>
          <p className={css.heroMainText}>
            your reliable partner
          </p>
          <div className={css.heroScrollDownContainer}>
            <div className={css.heroScrollDown}>
              <img src="/content/hello/scroll-down.svg" alt="Scroll Down"/>
            </div>
            <div className={css.heroScrollDownArrow}>
              <img src="/content/general/arrow.svg" alt="Arrow"/>
            </div>
          </div>
        </div>
      </div>

      <div className={css.heroBubbles}
          ref={heroBubbles}
        >
          <div className={css.heroBubblesImg}>
            <img src="/content/hello/hero-bubbles.png" alt="Bubbles"/>
          </div>
      </div>

      <div className={css.heroMessage}
          ref={heroMessage}
      >
        <div className={css.heroMessageMain}>
          <div className={css.heroMessageMainTitle}>
            <p className={css.heroMessageMainTitleText}>
              01 <br/> Tagline Intro
            </p>
          </div>
          <div className={css.heroMessageMainText}
              ref={heroMessageMainText}
          >
            <p className={css.heroMessageMainTextFirst}>
              Hi, it's Alphabet Capital
            </p>
            <p className={css.heroMessageMainTextSecond}>
              We are a <span className={css.teamExperts}>team of experts</span> that deals with all aspects of business matters and corporate disputes
            </p>
            <p className={css.heroMessageMainTextThird}
              ref={heroMessageText}
            >
              Your reliable partner in solving complex corporate issues
            </p>
          </div>
        </div>
        <div className={css.heroMessageFooter}
            ref={heroMessageFooter}
        >
          <button className={css.heroMessageFooterBtn}>
            <p className={css.heroMessageFooterBtnText}>
              advantage
            </p>
            <div className={css.heroMessageArrow}>
              <img src="/content/general/arrow.svg" alt="Arrow"/>
            </div>
          </button>
        </div>
      </div>

      <div className={css.advantageBubbles}
           ref={advantageBubbles}
        >
          <img src="/content/hello/advantage-bubbles.png" alt="Advantage Bubbles"/>
      </div>

      <div className={css.expertise}
           ref={expertise}
      >
        <div className={css.expertiseMain}>
          <p className={css.expertiseMainTitle}>
            Expertise
          </p>
          <p className={css.expertiseMainDescription}>
            Experts in all aspects of business matters and corporate disputes
          </p>
          <div className={css.expertiseScrollDownContainer}>
            <div className={css.expertiseScrollDown}
                 ref={advantageScrollDown}
            >
              <img src="/content/hello/scroll-down.svg" alt="Scroll Down"/>
            </div>
            <div className={css.experitseScrollDownArrow}>
              <img src="/content/general/arrow.svg" alt="Arrow"/>
            </div>
          </div>
        </div>
      </div>

      <div className={css.perfomance}
           ref={perfomance}
      >
        <div className={css.perfomanceMain}>
          <p className={css.perfomanceMainTitle}>
            Performance
          </p>
          <p className={css.perfomanceMainDescription}>
            Whether you're an enterprise, corporation, or start-up, we provide our clients with the technical and business support needed for success
          </p>
          <div className={css.perfomanceScrollDownContainer}>
            <div className={css.perfomanceScrollDown}>
              <img src="/content/hello/scroll-down.svg" alt="Scroll Down"/>
            </div>
            <div className={css.perfomanceScrollDownArrow}>
              <img src="/content/general/arrow.svg" alt="Arrow"/>
            </div>
          </div>
        </div>
      </div>

      <div className={css.trust}
           ref={trust}
      >
        <div className={css.trustMain}>
          <p className={css.trustMainTitle}>
            The Trust
          </p>
          <p className={css.trustMainDescription}>
            Having a trusting relationship with our business partners is paramount to maintaining a competitive advantage
          </p>
          <div className={css.trustScrollDownContainer}>
            <div className={css.trustScrollDown}>
              <img src="/content/hello/scroll-down.svg" alt="Scroll Down"/>
            </div>
            <div className={css.trustScrollDownArrow}>
              <img src="/content/general/arrow.svg" alt="Arrow"/>
            </div>
          </div>
        </div>
      </div>

      <div className={css.directions}>
        <div className={css.directionsMain}>
          <div className={css.directionsMainTitle}>
            <p className={css.directionsMainTitleText}>
              How and where <br/> we can help
            </p>
          </div>
          <div className={css.dirctionsImgQlobus}>
            <img src="/content/hello/qlobus.svg" alt="Qlobus Image"/>
          </div>
          <div className={css.directionsGif}>
            <img src="/content/hello/qlobus.gif" alt="Silver Animation"/>
          </div>
        </div>
        <div className={css.accordion}>
          <ul className={css.accordionList}>
            <li className={css.accordionItem}>
              <div className={css.accordionSection}>
                <button className={`${css.accordion} ${setActiveInvestment}`}
                        onClick={toggleAccordionInvestment}
                >
                  <p className={`${css.accordionTitle} ${setActiveInvestment}`}>
                    Investment
                  </p>
                  <div>
                    <svg className={`${setRotateInvestment}`} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" />
                      <path d="M19.9998 8.14355L21.6766 18.3231L31.8561 19.9998L21.6766 21.6766L19.9998 31.8561L18.3231 21.6766L8.14355 19.9998L18.3231 18.3231L19.9998 8.14355Z" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M20 37.5689C29.703 37.5689 37.5689 29.703 37.5689 20C37.5689 10.297 29.703 2.43114 20 2.43114C10.297 2.43114 2.43114 10.297 2.43114 20C2.43114 29.703 10.297 37.5689 20 37.5689ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z" />
                    </svg>
                  </div>
                </button>
                <div
                    ref={contentInvestment}
                    style={{ maxHeight: `${setHeightInvestment}` }}
                    className={css.accordionContent}
                >
                  <div className={`${css.accordionText} ${setActiveInvestment}`}>
                    <p>
                      The main goal of our investment is to acquire and improve depressed assets for further sale. 
                    </p>
                    <br />
                    <div className={css.accordionLink}>
                      <Link to='/services'> 
                        more Info 
                      </Link> 
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className={css.accordionItem}>
              <div className={css.accordionSection}>
                <button className={`${css.accordion} ${setActiveFollowUp}`}
                        onClick={toggleAccordionFollowUp}
                >
                  <p className={`${css.accordionTitle} ${setActiveFollowUp}`}>
                    Follow Up
                  </p>
                  <div>
                    <svg className={`${setRotateFollowUp}`} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" />
                      <path d="M19.9998 8.14355L21.6766 18.3231L31.8561 19.9998L21.6766 21.6766L19.9998 31.8561L18.3231 21.6766L8.14355 19.9998L18.3231 18.3231L19.9998 8.14355Z" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M20 37.5689C29.703 37.5689 37.5689 29.703 37.5689 20C37.5689 10.297 29.703 2.43114 20 2.43114C10.297 2.43114 2.43114 10.297 2.43114 20C2.43114 29.703 10.297 37.5689 20 37.5689ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z" />
                    </svg>
                  </div>
                </button>
                <div
                    ref={contentFollowUp}
                    style={{ maxHeight: `${setHeightFollowUp}` }}
                    className={css.accordionContent}
                >
                  <div className={`${css.accordionText} ${setActiveFollowUp}`}>
                    <p>
                      We provide services to support M&A transactions, alongside legal and financial support for investment projects.
                    </p>
                    <br />
                    <div className={css.accordionLink}>
                      <Link to='/services'> 
                        more Info 
                      </Link> 
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className={css.accordionItem}>
              <div className={css.accordionSection}>
                <button className={`${css.accordion} ${setActiveAssetManagment}`}
                        onClick={toggleAccordionAssetManagment}
                >
                  <p className={`${css.accordionTitle} ${setActiveAssetManagment}`}>
                    Asset Management
                  </p>
                  <div>
                    <svg className={`${setRotateAssetManagment}`} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" />
                      <path d="M19.9998 8.14355L21.6766 18.3231L31.8561 19.9998L21.6766 21.6766L19.9998 31.8561L18.3231 21.6766L8.14355 19.9998L18.3231 18.3231L19.9998 8.14355Z" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M20 37.5689C29.703 37.5689 37.5689 29.703 37.5689 20C37.5689 10.297 29.703 2.43114 20 2.43114C10.297 2.43114 2.43114 10.297 2.43114 20C2.43114 29.703 10.297 37.5689 20 37.5689ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z" />
                    </svg>
                  </div>
                </button>
                <div
                    ref={contentAssetManagment}
                    style={{ maxHeight: `${setHeightAssetManagment}` }}
                    className={css.accordionContent}
                >
                  <div className={`${css.accordionText} ${setActiveAssetManagment}`}>
                    <p>
                      We are experts in corporate governance and financial risk management. 
                    </p>
                    <br />
                    <div className={css.accordionLink}>
                      <Link to='/services'> 
                        more Info 
                      </Link> 
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className={css.accordionItem}>
              <div className={css.accordionSection}>
                <button className={`${css.accordion} ${setActiveAntiCrisisManagment}`}
                        onClick={toggleAccordionAntiCrisisManagment}
                >
                  <p className={`${css.accordionTitle} ${setActiveAntiCrisisManagment}`}>
                    Anti-Crisis Management
                  </p>
                  <div>
                    <svg className={`${setRotateAntiCrisisManagment}`} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" />
                      <path d="M19.9998 8.14355L21.6766 18.3231L31.8561 19.9998L21.6766 21.6766L19.9998 31.8561L18.3231 21.6766L8.14355 19.9998L18.3231 18.3231L19.9998 8.14355Z" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M20 37.5689C29.703 37.5689 37.5689 29.703 37.5689 20C37.5689 10.297 29.703 2.43114 20 2.43114C10.297 2.43114 2.43114 10.297 2.43114 20C2.43114 29.703 10.297 37.5689 20 37.5689ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z" />
                    </svg>
                  </div>
                </button>
                <div
                    ref={contentAntiCrisisManagment}
                    style={{ maxHeight: `${setHeightAntiCrisisManagment}` }}
                    className={css.accordionContent}
                >
                  <div className={`${css.accordionText} ${setActiveAntiCrisisManagment}`}>
                    <p>
                      Acting as mediators in various disputes, we strive to find optimal solutions to the most complex issues. 
                    </p>
                    <br />
                    <div className={css.accordionLink}>
                      <Link to='/services'> 
                        more Info 
                      </Link> 
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      
      <Footer />
    </div>
  )
}

export default Hello;
