import css from "./Contact.module.css";

import Menu from "../../components/Menu/Menu";
import Footer from "../../components/Footer/Footer";
import { globalContext } from "../../context/globalContext";

import { useContext, useEffect, useRef, useState } from "react";

function Contact() {

  const { toogleMenu } = useContext( globalContext );

  useEffect(() => {

    setTimeout(() => {
      window.scrollTo(0,0);
    }, 700);

  }, []);

  const [isActiveAddress, setIsActiveAddress] = useState(false);
  const [isActiveMail, setIsActiveMail] = useState(false);
  const [isActivePhone, setIsActivePhone] = useState(false);

  const address = useRef(null);
  const mail = useRef(null);
  const phone = useRef(null);

  function toggleAddress() {
    address.current.classList.toggle(css.addressIsActive);
    setIsActiveAddress(!isActiveAddress);

    if(!isActiveAddress) {
      
      if(isActiveMail) {
        toggleMail();
      }
      if(isActivePhone) {
        togglePhone();
      }
    }
  }

  function toggleMail() {
    mail.current.classList.toggle(css.mailIsActive);
    setIsActiveMail(!isActiveMail);

    if(!isActiveMail) {

      if(isActiveAddress) {
        toggleAddress();
      }
      if(isActivePhone) {
        togglePhone();
      }
    }
  }

  function togglePhone() {
    phone.current.classList.toggle(css.phoneIsActive);
    setIsActivePhone(!isActivePhone);

    if(!isActivePhone) {

      if(isActiveAddress) {
        toggleAddress();
      }
      if(isActiveMail) {
        toggleMail();
      }
    }
  }

  return (
    <>
      <div className={css.contact}>
        <div className={css.contactMenu}>
          <Menu />
        </div>
        <div className={css.contactContainer}>
          <div className={css.contactHeader}>
            <div className="title__container">
              <div className={css.titlePoint}></div>
              <p className="title">
                contact
              </p>
            </div>
            <div className={css.menu}>
              <div className='header-burger'
                  onClick={toogleMenu} 
              >
                <span></span>
              </div>
            </div>
          </div>
          <div className={css.contactBody}>
            <p className={css.contactBodyHeader}>
              Have an idea?
            </p>
            <p className={css.contactBodyTitle}>
              Let's get in touch
            </p>
            <div className={css.contactBodyFooter}>
              <a className={css.contactBodyMail} href="mailto:a@a1.fund" target="_blank" rel="noreferrer">
                a@a1.fund
              </a>
            </div>
          </div>
          <div className={css.contactFooter}>
            <div className={css.address}
                 ref={address}
                 onClick={toggleAddress}
            >
              <a className={css.addressText} href="https://goo.gl/maps/qbogJ9vCuL51VpFv5" target="_blank" rel="noreferrer">
                12/F, Tower 3, 33 Canton Road, Tsim Sha Tsui, Kowloon, Hong Kong
              </a>
            </div>
            <div className={css.mail}
                 ref={mail}
                 onClick={toggleMail}
            >
              <a className={css.mailText} href="mailto:a@a1.fund" target="_blank" rel="noreferrer">
                a@a1.fund
              </a>
            </div>
            <div className={css.phone}
                 ref={phone}
                 onClick={togglePhone}
            >
              <a className={css.phoneText} href="tel:+447888969993">
                +447 888 96 99 93
              </a>
              <a className={css.phoneText} href="tel:+447902000007">
                +44 7902 000 007
              </a>
            </div>
          </div>
        </div>
        <div className={css.contactImage}>
          <img src="/content/contact/get-in-touch.gif" alt="Background Gif"/>
        </div>
      </div>
      <div className={css.footer}>
        <Footer />
      </div>
    </>
  )
}

export default Contact;
