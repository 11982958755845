import { Link } from 'react-router-dom';
import css from './Footer.module.css';

function Footer() {
  return (
    <>
    <div className={css.footer}>
      <div className={css.footerContainer}>
        <div className={css.footerHero}>
          <p className={css.footerHeroText}>
            Your  reliable partner
          </p>
          <div className={css.footerHeroTitle}>
            <img src="/content/footer/alphabet-capital-footer.svg" alt="Alphabet Capital"/>
          </div>
        </div>
        <div className={css.footerContacts}>
          <div className={css.footerGetInTouch}>
            <p className={css.footerGetInTouchTitle}>
              get in touch
            </p>
            <ul className={css.footerGetInTouchList}>
              <li className={css.footerGetInTouchListItem}>
                <a className={css.footerGetInTouchMailLink} href="mailto:a@a1.fund">
                  a@a1.fund
                </a>
              </li>
              <li className={css.footerGetInTouchListItem}>
                <a className={css.footerGetInTouchPhoneLink} href="tel:+447888969993">
                  +447 888 96 99 93
                </a>
              </li>
              <li className={css.footerGetInTouchListItem}>
                <a className={css.footerGetInTouchPhoneLink} href="tel:+447902000007">
                +44 7902 000 007
                </a>
              </li>
            </ul>
          </div>
          <div className={css.footerExplore}>
            <p className={css.footerExploreTitle}>
              Explore
            </p>
            <ul className={css.footerExploreList}>
              <li className={css.footerExploreListItem}>
                <Link to="/about">
                  <p className={css.footerExploreLink}>
                    About  
                  </p>
                </Link> 
              </li>
              <li className={css.footerExploreListItem}>
                <Link to="/services">
                  <p className={css.footerExploreLink}>
                    Services  
                  </p>
                </Link> 
              </li>
              <li className={css.footerExploreListItem}>
                <Link to="/contact">
                  <p className={css.footerExploreLink}>
                    Contact  
                  </p>
                </Link> 
              </li>
            </ul>
            <p className={css.footerAddressText}>
             12/F, Tower 3, 33 Canton Road, Tsim Sha Tsui, Kowloon, Hong Kong
            </p>
            <div className={css.footerAddress}>
              <a className={css.footerAddressLink} href="https://goo.gl/maps/qbogJ9vCuL51VpFv5" target="_blank" rel="noreferrer">
                see on map
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Footer;
